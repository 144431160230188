// Select the div with the specific classes
const btnWrapper = document.querySelector('.fui-btn-wrapper');

// If the button wrapper exists
if (btnWrapper) {
    // Find the button inside the wrapper
    const button = btnWrapper.querySelector('button');
    
    if (button) {
        // Get the classes of the wrapper (excluding 'fui-btn-wrapper')
        const classesToMove = Array.from(btnWrapper.classList).filter(cls => cls !== 'fui-btn-wrapper');

        // Add the filtered classes to the button
        button.classList.add(...classesToMove);

        // Optionally, remove the wrapper (if no longer needed)
        // This will move the button out of the wrapper
        btnWrapper.replaceWith(button);
    }
}