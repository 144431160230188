document.addEventListener('DOMContentLoaded', function () {
    document.querySelectorAll('.gallery-image').forEach(image => {
        image.addEventListener('click', function() {
            const lightbox = document.getElementById('lightbox');
            const lightboxImage = document.getElementById('lightboxImage');
            
            lightboxImage.src = this.src; // Set lightbox image source
            lightbox.style.display = 'flex'; // Show lightbox
        });
    });

    document.getElementById('close').addEventListener('click', function() {
        const lightbox = document.getElementById('lightbox');
        lightbox.style.display = 'none'; // Hide lightbox
    });

    // Hide lightbox when clicking outside the image
    document.getElementById('lightbox').addEventListener('click', function(e) {
        if (e.target !== this) return; // Ignore clicks on the image
        this.style.display = 'none'; // Hide lightbox
    });
});
