document.addEventListener('DOMContentLoaded', () => {
    const setFavicon = () => {
        const favicon = document.querySelector('link[rel="icon"]');

        if (window.companyFavicons) {
            favicon.href = window.matchMedia('(prefers-color-scheme: dark)').matches
                ? window.companyFavicons.darkModeFavicon 
                : window.companyFavicons.lightModeFavicon; 
        } else {
            console.error('Favicons not found in companyFavicons object');
        }
    };

    setFavicon(); // Initial call to set favicon

    // Listen for changes to the prefers-color-scheme media query
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', setFavicon);
});